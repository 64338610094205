import Vue from "vue";
import Carousel from "../vue/dist/Carousel.vue";
import Vuex from "vuex";
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    count: 0,
  },
  getters: {
    count: (state) => state.count,
  },
  mutations: {
    changeCurrent(state, val) {
      state.count = val;
    },
  },

  actions: {
    changeCurrent(context) {
      context.commit("changeCurrent");
    },
  },
});

(function ($) {
  window.$ = $;
  if ($(".carousel").length > 0) {
    var mountEl = document.getElementsByClassName("carousel");
    Array.from(mountEl).forEach(el => {
      new Vue({
        propsData: { ...el.dataset },
        props: ["carousel", "options","carousel-options"],
        store: store,
        render: (h) => h(Carousel),
      }).$mount(el)
    });
  }
})(jQuery);