var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.carouselClass, style: "width:100%;height:" + _vm.height },
    [
      _c(
        "VueSlickCarousel",
        _vm._b({}, "VueSlickCarousel", _vm.options, false),
        _vm._l(_vm.someList, function(item, index) {
          return _c("div", { key: index, style: item.style }, [
            _c(
              "figure",
              {
                staticClass: "image-item gallery-item",
                style: "background-image:url(" + item.url + ")",
                attrs: {
                  itemprop: "associatedMedia",
                  itemscope: "",
                  itemtype: "http://schema.org/ImageObject"
                },
                on: {
                  click: function($event) {
                    _vm.showLightbox ? _vm.showPhotoSwipe(index) : ""
                  }
                }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "gallery__img--main",
                    attrs: {
                      href: item.url,
                      "data-msrc": "",
                      itemprop: "contentUrl",
                      "data-size": item.width + "x" + item.height
                    }
                  },
                  [_c("img", { attrs: { src: item.url } })]
                ),
                _vm._v(" "),
                item.title
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "container carousel-with-title" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-xs-12 col-md-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex-xs justify-center-xs direction-column"
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticClass: "carousel-with-title-h1" },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "h3",
                                    { staticClass: "carousel-with-title-h3" },
                                    [_vm._v(_vm._s(item.description))]
                                  ),
                                  _vm._v(" "),
                                  item.link
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "link-double-border red",
                                          attrs: { href: item.link.url }
                                        },
                                        [
                                          _c("span", { staticClass: "text" }, [
                                            _c(
                                              "span",
                                              { staticClass: "arrow" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticStyle: {
                                                      "enable-background":
                                                        "new 0 0 120.64 122.88"
                                                    },
                                                    attrs: {
                                                      version: "1.1",
                                                      id: "Layer_1",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      "xmlns:xlink":
                                                        "http://www.w3.org/1999/xlink",
                                                      x: "0px",
                                                      y: "0px",
                                                      width: "25",
                                                      height: "25",
                                                      viewBox:
                                                        "0 0 120.64 122.88",
                                                      "xml:space": "preserve"
                                                    }
                                                  },
                                                  [
                                                    _c("g", [
                                                      _c("path", {
                                                        attrs: {
                                                          fill: "#fff",
                                                          d:
                                                            "M54.03,108.91c-1.55,1.63-2.31,3.74-2.28,5.85c0.03,2.11,0.84,4.2,2.44,5.79l0.12,0.12c1.58,1.5,3.6,2.23,5.61,2.2 c2.01-0.03,4.01-0.82,5.55-2.37c17.66-17.66,35.13-35.61,52.68-53.4c0.07-0.05,0.13-0.1,0.19-0.16c1.55-1.63,2.31-3.76,2.28-5.87 c-0.03-2.11-0.85-4.21-2.45-5.8l-0.27-0.26C100.43,37.47,82.98,19.87,65.46,2.36C63.93,0.82,61.93,0.03,59.92,0 c-2.01-0.03-4.03,0.7-5.61,2.21l-0.15,0.15c-1.57,1.58-2.38,3.66-2.41,5.76c-0.03,2.1,0.73,4.22,2.28,5.85l47.22,47.27 L54.03,108.91L54.03,108.91z M2.26,106.91c-1.54,1.62-2.29,3.73-2.26,5.83c0.03,2.11,0.84,4.2,2.44,5.79l0.12,0.12 c1.57,1.5,3.6,2.23,5.61,2.21c2.01-0.03,4.02-0.82,5.55-2.37C31.01,101.2,48.87,84.2,66.39,67.12c0.07-0.05,0.14-0.11,0.21-0.17 c1.55-1.63,2.31-3.76,2.28-5.87c-0.03-2.11-0.85-4.21-2.45-5.8C48.94,38.33,31.36,21.44,13.83,4.51l-0.12-0.13 c-1.53-1.54-3.53-2.32-5.54-2.35C6.16,2,4.14,2.73,2.56,4.23L2.41,4.38C0.84,5.96,0.03,8.05,0,10.14c-0.03,2.1,0.73,4.22,2.28,5.85 l47.18,45.24L2.26,106.91L2.26,106.91z"
                                                        }
                                                      })
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item.link.title)
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("v-photoswipe", {
        attrs: {
          isOpen: _vm.isOpen,
          items: _vm.someList,
          options: _vm.options
        },
        on: { close: _vm.hidePhotoSwipe }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }