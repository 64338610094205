<template>
  <!-- Make a div wrapped slider,set height and width -->
  <div :style="'width:100%;height:' + height" :class="carouselClass">
    <VueSlickCarousel v-bind="options">
      <div v-for="(item, index) in someList" :key="index" :style="item.style">
        <figure
          class="image-item gallery-item"
          @click="showLightbox ? showPhotoSwipe(index) : ''"
          :style="'background-image:url(' + item.url + ')'"
          itemprop="associatedMedia"
          itemscope
          itemtype="http://schema.org/ImageObject"
        >
          <a
            :href="item.url"
            data-msrc=""
            itemprop="contentUrl"
            :data-size="item.width + 'x' + item.height"
            class="gallery__img--main"
          >
            <img :src="item.url" />
          </a>
          <div v-if="item.title">
            <div class="container carousel-with-title">
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <div class="d-flex-xs justify-center-xs direction-column">
                    <h1 class="carousel-with-title-h1">{{ item.title }}</h1>
                    <h3 class="carousel-with-title-h3">{{ item.description }}</h3>
                    <a v-if="item.link" :href="item.link.url" class="link-double-border red">
                    <span class="text">
                      <span class="arrow"
                        ><!--?xml version="1.0" encoding="utf-8"?--><!--?xml version="1.0" encoding="utf-8"?--><svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="25"
                          height="25"
                          viewBox="0 0 120.64 122.88"
                          style="enable-background:new 0 0 120.64 122.88"
                          xml:space="preserve"
                        >
                          <g>
                            <path
                              fill="#fff"
                              d="M54.03,108.91c-1.55,1.63-2.31,3.74-2.28,5.85c0.03,2.11,0.84,4.2,2.44,5.79l0.12,0.12c1.58,1.5,3.6,2.23,5.61,2.2 c2.01-0.03,4.01-0.82,5.55-2.37c17.66-17.66,35.13-35.61,52.68-53.4c0.07-0.05,0.13-0.1,0.19-0.16c1.55-1.63,2.31-3.76,2.28-5.87 c-0.03-2.11-0.85-4.21-2.45-5.8l-0.27-0.26C100.43,37.47,82.98,19.87,65.46,2.36C63.93,0.82,61.93,0.03,59.92,0 c-2.01-0.03-4.03,0.7-5.61,2.21l-0.15,0.15c-1.57,1.58-2.38,3.66-2.41,5.76c-0.03,2.1,0.73,4.22,2.28,5.85l47.22,47.27 L54.03,108.91L54.03,108.91z M2.26,106.91c-1.54,1.62-2.29,3.73-2.26,5.83c0.03,2.11,0.84,4.2,2.44,5.79l0.12,0.12 c1.57,1.5,3.6,2.23,5.61,2.21c2.01-0.03,4.02-0.82,5.55-2.37C31.01,101.2,48.87,84.2,66.39,67.12c0.07-0.05,0.14-0.11,0.21-0.17 c1.55-1.63,2.31-3.76,2.28-5.87c-0.03-2.11-0.85-4.21-2.45-5.8C48.94,38.33,31.36,21.44,13.83,4.51l-0.12-0.13 c-1.53-1.54-3.53-2.32-5.54-2.35C6.16,2,4.14,2.73,2.56,4.23L2.41,4.38C0.84,5.96,0.03,8.05,0,10.14c-0.03,2.1,0.73,4.22,2.28,5.85 l47.18,45.24L2.26,106.91L2.26,106.91z"
                            ></path>
                          </g>
                        </svg>
                      </span>
                      {{ item.link.title }}</span
                    >
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </figure>
      </div>
    </VueSlickCarousel>
    <v-photoswipe
      :isOpen="isOpen"
      :items="someList"
      :options="options"
      @close="hidePhotoSwipe"
    ></v-photoswipe>
  </div>
</template>

<script>
// import slider components
import VueSlickCarousel from "vue-slick-carousel";
import { PhotoSwipe, PhotoSwipeGallery } from "v-photoswipe";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "carousel",
  components: {
    VueSlickCarousel,
    "v-photoswipe": PhotoSwipe,
    "v-photoswipe-gallery": PhotoSwipeGallery,
  },
  data() {
    return {
      isOpen: false,
      carouselClass: "",
      someList: [
        {
          html: "slide1",
          style: {
            background: "#1bbc9b",
          },
        },
        {
          html: "slide2",
          style: {
            background: "#4bbfc3",
          },
        },
        {
          html: "slide3",
          style: {
            background: "#7baabe",
          },
        },
      ],
      //Slider configuration [obj]
      options: {
        currentPage: 0,
        dots: true,
        index: 0,
        fullscreenEl: 0,
        zoomEl: 0,
        shareEl: 0,
      },
      height: 400,
    };
  },
  mounted: function() {
    let list = JSON.parse(this.$parent.$props.carousel);

    list = list.map((el) =>
      Object.assign(el, { src: el.url, w: el.width, h: el.height })
    );

    if (list.length > 0) {
      this.someList = list;
    }

    this.options.dots = list.length > 1;

    if (this.$parent.$props.carouselOptions) {
      let carouselOptions = JSON.parse(this.$parent.$props.carouselOptions);
      this.height = carouselOptions.height;
      this.showLightbox = carouselOptions.showLightbox;
      this.options.dots = carouselOptions.showDots
        ? carouselOptions.showDots
        : list.length > 1;
    }

    if (this.$parent.$props.options) {
      this.options = {
        ...this.options,
        ...JSON.parse(this.$parent.$props.options),
      };

      this.options.responsive = [
        { breakpoint: 900, settings: { slidesToShow: 3, slidesToScroll: 1 } },
        {
          breakpoint: 600,
          settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 },
        },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      ];
      this.carouselClass = "dots-on-bottom";
    }
  },
  watch: {},
  methods: {
    showPhotoSwipe(index) {
      this.isOpen = true;
      this.$set(this.options, "index", index);
    },
    hidePhotoSwipe() {
      this.isOpen = false;
    },
  },
};
</script>
<style lang="scss">
$secondColor: #e7501e;
$white: #fff;

.image-item {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.slick-slide {
  img {
    display: none !important;
  }
}

.slick-dots {
  bottom: 20px !important;
  li {
    transition: all 0.2s linear;
  }
  li button:before {
    width: 100% !important;
    height: 5px !important;
    background: $white !important;
    opacity: 1 !important;
    border-radius: 5%;
    content: "";
    transition: all 0.2s linear;
  }
  .slick-active {
    width: 100px;
    button {
      &:before {
        opacity: 1;
        -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
      }
    }
  }
}

.dots-on-bottom {
  padding-bottom: 25px;
  margin-bottom: 25px;
  .slick-dots {
    bottom: -25px !important;
    position: relative;
  }
  .slick-slide > div {
    padding-left: 30px;
  }
  .slick-list {
    margin-left: -30px;
  }
  .slick-track {
    display: flex !important;
  }
}

.slick-slider,
.slick-track,
.slick-list,
.slick-slide,
.slick-slide div {
  height: 100%;
}
</style>
